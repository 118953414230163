import { HIDE_METAFIELD } from '../constants/metafieldConstants'

// Retrieve first variant of the product, ignores any variant with metafield hide in true
export const retrieveFirstVariant = variants => {
  const firstVariant = variants.edges.find(variant => {
    let hide = variant.node.metafields.edges.find(metafield => {
      return metafield.node.key === HIDE_METAFIELD
    })
    return hide === false || hide === undefined
  })
  return firstVariant.node
}

// Retrieve first variant of the product, ignores any variant with metafield hide in true
export const retrieveVariantByLevel = (variants, level) => {
  const resultVariant = variants.edges.find(variant => {
    return variant.node.title === level
  })
  return resultVariant.node
}

export const retrieveVariants = variants => {
  return variants.edges
    .filter(variant => {
      let hide = variant.node.metafields.edges.find(metafield => {
        return metafield.node.key === 'hide'
      })
      return hide === false || hide === undefined
    })
    .map(variant => {
      return variant.node
    })
}

export const retrieveVariantsForQuiz = variants => {
  return variants.edges
    .filter(variant => {
      let hide = variant.node.metafields.edges.find(metafield => {
        return metafield.node.key === 'hide'
      })
      return hide === false || hide === undefined
    })
    .map(variant => {
      const variantId = retrieveIdFromStorefrontId(variant.node.id)
      const levelName = variant.node.title.replace(/\s+/g, '_').toLowerCase()
      const price = moneyWithoutTrailingZeros(variant.node.price)
      return { variantId, levelName, price, score: 0 }
    })
}

export const retrieveSimpleVariants = variants => {
  const mappedVariants = variants.edges.map(variant => {
    return { price: variant.node.price, title: variant.node.title, id: variant.node.id }
  })
  return mappedVariants
}

export const retrieveProductMetafieldValue = (product, metafieldName) => {
  return product.metafields?.edges?.find(metafield => {
    return metafield.node.key === metafieldName
  })?.node?.value
}

export const moneyWithoutTrailingZeros = money => {
  return money.toString().replace('.00', '')
}

export const retrieveOneTimeVariant = (variants, variantSelected) => {
  let foundOption
  const expectedOption = variantSelected.selectedOptions.find(option => {
    return option.name === 'System'
  }).value

  return variants.edges.find(variant => {
    foundOption = variant.node.selectedOptions.find(option => {
      return option.value === expectedOption
    })
    if (foundOption) {
      return variant
    }
  })
}

export const retrieveOneTimeVariantPrice = (variants, variantOption) => {
  const oneTimeVariant = retrieveOneTimeVariant(variants, variantOption)
  return oneTimeVariant ? moneyWithoutTrailingZeros(oneTimeVariant.node.price) : ''
}

export const retrieveIdFromStorefrontId = encodedId => {
  const buffer = Buffer.from(encodedId, 'base64')
  const decodedUrl = buffer.toString()
  const parsedUrl = decodedUrl.split('/')
  return parsedUrl[parsedUrl.length - 1]
}

// Retrieve first variant of the product, ignores any variant with metafield hide in true
export const retrieveVariantById = (variants, variantId) => {
  const variant = variants.edges.find(variant => {
    return retrieveIdFromStorefrontId(variant.node.id) === variantId
  })
  return variant === undefined ? null : variant.node
}

export const retrieveVariantsAndExclude = (variants, variantIdToExclude) => {
  const mappedVariants = variants.edges
    .filter(variant => {
      let hide = variant.node.metafields.edges.find(metafield => {
        return metafield.node.key === 'hide'
      })
      let exclude = retrieveIdFromStorefrontId(variant.node.id) === variantIdToExclude
      return (hide === false || hide === undefined) && exclude === false
    })
    .map(variant => {
      return variant.node
    })
  return mappedVariants
}
