import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import Flickity from 'flickity'
import 'flickity/dist/flickity.min.css'

const Slider = props => {
  // Props and States
  const [flickityReady, setFlickityReady] = useState(false)
  const [flickity, setFlickity] = useState(undefined)
  const flickityNode = useRef(undefined)
  const { options, children, setArrowNavHandler } = props

  // UseEffects
  useEffect(() => {
    setFlickity(new Flickity(flickityNode.current, options || {}))
    setFlickityReady(true)
    return () => {
      flickity && flickity.destroy()
    }
  }, [])

  useEffect(() => {
    if (flickityReady && children.length) {
      refreshFlickity()
    }
  }, [flickityReady, children])
  // Functions
  const refreshFlickity = () => {
    flickity.reloadCells()
    flickity.resize()
    flickity.updateDraggable()
    if(setArrowNavHandler) setArrowNavHandler(flickity)
  }
  const renderPortal = () => {
    if (!flickityNode.current) {
      return null
    }
    const mountNode = flickityNode.current.querySelector('.flickity-slider')
    if (mountNode) {
      return ReactDOM.createPortal(children, mountNode)
    }
  }
  // Render
  return [<div key="flickityBase" ref={flickityNode} />, renderPortal()].filter(Boolean)
}

export default Slider
