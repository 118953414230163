import { gql } from '@apollo/client'

export const SKIN_CARE_SYSTEM_QUERY = gql`
  query SkinCareProduct($handle: String!, $namespace: String!) {
    productByHandle(handle: $handle) {
      id
      title
      metafield(key: "onetime_product", namespace: $namespace) {
        value
      }
      variants(first: 5) {
        edges {
          node {
            id
            title
            price
            image {
              originalSrc
              transformedSrc
            }
            metafields(first: 10) {
              edges {
                node {
                  key
                  value
                }
              }
            }
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  }
`

export const ONE_TIME_PRODUCT_QUERY = gql`
  query Product($handle: String!) {
    productByHandle(handle: $handle) {
      id
      title
      variants(first: 5) {
        edges {
          node {
            id
            title
            price
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  }
`

export const PRODUCT_QUERY = gql`
  query IncludedProduct($handle: String!) {
    productByHandle(handle: $handle) {
      title
      metafield(key: "included_product_image", namespace: "accentuate") {
        value
      }
    }
  }
`

export const GIFT_PRODUCTS_QUERY = gql`
  query GiftProduct($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on ProductVariant {
        id
        price
        compareAtPrice
        title
        selectedOptions {
          name
          value
        }
        image {
          originalSrc
          transformedSrc
        }
        product {
          productType
          title
          images(first: 3) {
            edges {
              node {
                originalSrc
                transformedSrc
              }
            }
          }
          handle
          description
          metafields(namespace: "accentuate", first: 2) {
            edges {
              node {
                key
                value
              }
            }
          }
        }
      }
    }
  }
`

export const SYSTEM_VARIANTS_QUERY = gql`
  query SystemVariantsQuery($handle: String!) {
    productByHandle(handle: $handle) {
      id
      title
      description
      images(first: 3) {
        edges {
          node {
            originalSrc
            transformedSrc
          }
        }
      }
      metafields(namespace: "accentuate", first: 3) {
        edges {
          node {
            key
            value
          }
        }
      }
      variants(first: 5) {
        edges {
          node {
            id
            title
            price
            image {
              originalSrc
              transformedSrc
            }
            metafields(first: 10) {
              edges {
                node {
                  key
                  value
                }
              }
            }
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  }
`

export const GET_PRODUCT_VARIANT = gql`
query GetProductVariant($id: ID! ) {
  node(id: $id) {
    ... on ProductVariant {
      id
      price
      selectedOptions {
        name
        value
      }
      product {
        metafield(key:"onetime_product", namespace:"accentuate") {
          value
        }
      }
    }
  }
}`