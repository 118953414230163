import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

function cta(props) {
  const { id, title, route, link, className, openNewTab, dataCy } = props
  if (route && route.slug && route.slug.current) {
    return (
      <Link
        className={className}
        href={{
          pathname: '/',
          query: { slug: route.slug.current }
        }}
        as={`/${route.slug.current}`}
        data-cy={dataCy ? `${dataCy}` : 'link'}
      >
        <a>{title}</a>
      </Link>
    )
  }

  if (link) {
    return (
      <a
        id={id}
        href={link}
        className={className}
        data-cy={dataCy ? `${dataCy}` : 'cta'}
        target={openNewTab ? '__blank' : ''}
      >
        {title}
      </a>
    )
  }

  return (
    <a id={id} className={className} data-cy={dataCy}>
      {title}
    </a>
  )
}

cta.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
  route: PropTypes.shape({
    slug: PropTypes.shape({
      current: PropTypes.string
    })
  }),
  link: PropTypes.string
}

export default cta
