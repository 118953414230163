import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

function StickyContent(props) {
  const [showButton, setShowButton] = useState(false)
  const { startAfterElementId, children, classOnShow, classOnHide, className } = props
  const afterElement = document.getElementById(startAfterElementId)

  const handleScroll = e => {
    const yOffset = window.pageYOffset || document.documentElement.scrollTop
    /**
     * Since the afterElement does not exist when rendering with JSDom
     * CSS style property is use to get height as an alternative.
     */
    const forTestingPurpose = +afterElement.style?.height?.replace('px', '')
    const elemHeight = afterElement ? afterElement.offsetHeight || forTestingPurpose : 0

    if (yOffset > elemHeight - 120) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <div
      data-testid="sticky-content"
      className={`sticky-content fixed w-full left-0 ${showButton ? classOnShow : classOnHide}${
        className ? ` ${className}` : ''
      }`}
    >
      {children}
    </div>
  )
}

StickyContent.propTypes = {
  startAfterElementId: PropTypes.string,
  children: PropTypes.object,
  className: PropTypes.string,
  classOnShow: PropTypes.string,
  classOnHide: PropTypes.string
}

StickyContent.defaultProps = {
  classOnShow: 'block',
  classOnHide: 'hidden'
}

export default StickyContent
