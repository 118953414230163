export const DEFAULT_NAMESPACE = 'accentuate'
export const SKIN_CARE_HANDLE = 'mens-skin-care-routine'

export const PRODUCT_MODAL_TYPE = {
  GIFT: 'gift',
  SYSTEM: 'system'
}

export const VARIANT_OPTION_NAMES = {
  SIZE: 'Size',
  TITLE: 'Title'
}