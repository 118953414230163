import React from 'react'
import PropTypes from 'prop-types'

function anchor(props) {
  const {
    title,
    anchorId,
    fontSizeDesktop,
    fontSizeMobile,
    anchorHeight,
    id,
    className,
    suffix
  } = props
  return (
    <a
      data-cy={`anchor-${suffix}`}
      id={id}
      href={`#${anchorId}`}
      className={`text-fs-${fontSizeMobile} sm:text-fs-${fontSizeDesktop} ${className} sm:h-${anchorHeight}px `}
    >
      {title}
    </a>
  )
}

anchor.propTypes = {
  title: PropTypes.string.isRequired,
  fontSizeDesktop: PropTypes.number,
  fontSizeMobile: PropTypes.number,
  className: PropTypes.string,
  anchorId: PropTypes.string
}

export default anchor
