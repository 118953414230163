import React from 'react'
import PropTypes from 'prop-types'
import Slider from '../Slider'

function Carousel(props) {
  const { children, carouselType, options, id, setArrowNavHandler } = props
  const carouselOptions = options || {
    initialIndex: 1,
    autoPlay: false,
    wrapAround: true,
    fullscreen: false,
    freeScroll: false,
    contain: true,
    lazyLoad: true
  }

  return (
    <div id={id || 'carousel'} className={`${carouselType || 'regular h-full'}`}>
      <Slider setArrowNavHandler={setArrowNavHandler} options={carouselOptions}>{children}</Slider>
    </div>
  )
}

Carousel.propTypes = {
  id: PropTypes.string,
  children: PropTypes.array,
  carouselType: PropTypes.string,
  options: PropTypes.object,
  setArrowNavHandler: PropTypes.func
}

export default Carousel
